import { render, staticRenderFns } from "./AddPostLoginSurvey.vue?vue&type=template&id=a1cf208a&scoped=true&"
import script from "./AddPostLoginSurvey.vue?vue&type=script&lang=js&"
export * from "./AddPostLoginSurvey.vue?vue&type=script&lang=js&"
import style0 from "./AddPostLoginSurvey.vue?vue&type=style&index=0&id=a1cf208a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1cf208a",
  null
  
)

export default component.exports